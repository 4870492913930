* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.underline {
  background: hsl(205, 78%, 60%);
  height: 0.25rem;
  width: 5rem;
  margin: 0.5rem auto;
  margin-bottom: 2rem; 
}


.twocolumnContainer {
  margin: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.centerContent {
  display: flex;
  justify-content: center;
}

.title {
  display: flex;
  justify-content: center;
  margin-top: 2rem !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
}

.about_photo {
  object-fit: cover;
  min-height: 72%;
  max-width: 72%;
  border-radius: 4%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  margin-bottom: 1rem;
}

.centeredContent {
  text-align: center;
  margin: 2rem auto;
  max-width: 1000px; /* Adjust this width as desired */
}

.image {
  margin-top: 1rem;
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.video-container {
  align-self: center;
  width: 100%;
  height: 400px;
  margin-top: 64px; /* Adjust this value as per your theme.spacing(8) */
  border-radius: 8px; /* Adjust this value as per theme.shape.borderRadius */
  outline: 6px solid;
  outline-color: hsla(220, 25%, 80%, 0.2);
  border: 1px solid;
  border-color: #d3d3d3; /* Adjust to your theme.palette.grey[200] */
  box-shadow: 0 0 12px 8px hsla(220, 25%, 80%, 0.2);
  position: relative;
  overflow: hidden;
}